import './App.css'
import { useEffect, useState } from 'react'
import Web3 from 'web3'
import { ethers } from 'ethers'
import {
  createWeb3Modal,
  defaultConfig,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalEvents,
  useWeb3ModalState
} from '@web3modal/ethers5/react'
import {
  PROJECT_ID,
  METADATA,
  CHAINS,
  CURRENCY_ICONS,
  ADDRESSES,
  MIN_ABI,
  BRIDGE_ABI,
  BRIDGE_STATUSES,
  MULT_COEFF,
  HASH_TIME_LIMIT
} from './config'
import { shortAddr, createTimestamp } from './utils'

createWeb3Modal({
  projectId: PROJECT_ID,
  ethersConfig: defaultConfig({ METADATA }),
  chains: CHAINS,
  defaultChain: CHAINS[0],
  featuredWalletIds: [
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
    '19177a98252e07ddfc9af2083ba8e07ef627cb6103467ffebb3f8f4205fd7927',
    '8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4'
  ],
  themeMode: 'dark'
})

const tokenOptions = [
  {
    label: 'choose token',
    value: ''
  },
  {
    label: 'Piastr',
    value: 'Piastr'
  },
  {
    label: 'Escudo',
    value: 'Escudo'
  }
]

let savedHashsArr = []

const App = () => {
  const { open } = useWeb3Modal()
  const { address, isConnected } = useWeb3ModalAccount()
  const { selectedNetworkId } = useWeb3ModalState()
  const events = useWeb3ModalEvents()

  const [loaded, setLoaded] = useState(false)
  const [account, setAccount] = useState(null)

  const [web3, setWeb3] = useState(null)
  const [clientSep, setClientSep] = useState(null)
  const [clientBnb, setClientBnb] = useState(null)

  const [contractSepPiastr, setContractSepPiastr] = useState(null)
  const [contractSepEscudo, setContractSepEscudo] = useState(null)
  const [contractSepBridge, setContractSepBridge] = useState(null)
  const [contractBnbPiastr, setContractBnbPiastr] = useState(null)
  const [contractBnbEscudo, setContractBnbEscudo] = useState(null)
  const [contractBnbBridge, setContractBnbBridge] = useState(null)

  const [balanceSepMain, setBalanceSepMain] = useState(null)
  const [balanceSepPiastr, setBalanceSepPiastr] = useState(null)
  const [balanceSepEscudo, setBalanceSepEscudo] = useState(null)
  const [balanceBnbMain, setBalanceBnbMain] = useState(null)
  const [balanceBnbPiastr, setBalanceBnbPiastr] = useState(null)
  const [balanceBnbEscudo, setBalanceBnbEscudo] = useState(null)
  const [savedHashs, setSavedHashs] = useState(null)

  const [tab, setTab] = useState(CHAINS[0].chainId)
  const [formVisible, setFormVisible] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [tokenOpened, setTokenOpened] = useState(false)
  const [tokenValue, setTokenValue] = useState('')
  const [recipientValue, setRecipientValue] = useState('')
  const [amountValue, setAmountValue] = useState('')

  const load = async () => {
    await loadWeb3()
  }

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', handleAccountsChanged)
      setLoaded(true)
    }
  }

  const handleAccountsChanged = (accounts) => {
    if (!account || !accounts[0] || account.toLowerCase() !== accounts[0].toLowerCase()) {
      setAccount(accounts[0])
    }
  }
  
  const clientsCreate = async () => {
    console.log('Create web3 client for current network')
    setWeb3(new Web3(window.ethereum))
    console.log('Create web3 client for Sepolia')
    let ch = CHAINS[0]
    let provider = new Web3.providers.HttpProvider(ch.rpcUrl)
    let client = new Web3(provider)
    setClientSep(client)
    console.log('Create web3 client for BNB')
    ch = CHAINS[1]
    provider = new Web3.providers.HttpProvider(ch.rpcUrl)
    client = new Web3(provider)
    setClientBnb(client)
    return true
  }
  
  const contractsSepCreate = async () => {
    console.log('Create contracts in Sepolia')
    const chain = CHAINS[0]
    const addr = ADDRESSES.find(el => el.chainId === chain.chainId)
    const objP = new clientSep.eth.Contract(MIN_ABI, addr.Piastr)
    const objE = new clientSep.eth.Contract(MIN_ABI, addr.Escudo)
    const objB = new clientSep.eth.Contract(BRIDGE_ABI, addr.Bridge)
    setContractSepPiastr(objP)
    setContractSepEscudo(objE)
    setContractSepBridge(objB)
  }
  
  const contractsBnbCreate = async () => {
    console.log('Create contracts in BNB')
    const chain = CHAINS[1]
    const addr = ADDRESSES.find(el => el.chainId === chain.chainId)
    const objP = new clientBnb.eth.Contract(MIN_ABI, addr.Piastr)
    const objE = new clientBnb.eth.Contract(MIN_ABI, addr.Escudo)
    const objB = new clientBnb.eth.Contract(BRIDGE_ABI, addr.Bridge)
    setContractBnbPiastr(objP)
    setContractBnbEscudo(objE)
    setContractBnbBridge(objB)
  }

  const getContractSepBalance = async () => {
    console.log('Get Piastr balance in Sepolia...')
    contractSepPiastr.methods.balanceOf(account).call()
      .then((receipt) => {
        console.log('Get Piastr balance in Sepolia success:', receipt)
        setBalanceSepPiastr(receipt / MULT_COEFF)
      })
      .catch((error) => {
        console.log('Get Piastr balance in Sepolia error:', error)
      })
    console.log('Get Escudo balance in Sepolia ...')
    contractSepEscudo.methods.balanceOf(account).call()
      .then((receipt) => {
        console.log('Get Escudo balance in Sepolia success:', receipt)
        setBalanceSepEscudo(receipt / MULT_COEFF)
      })
      .catch((error) => {
        console.log('Get Escudo balance in Sepolia error:', error)
      })
  }

  const getContractBnbBalance = async () => {
    console.log('Get Piastr balance in BNB...')
    contractBnbPiastr.methods.balanceOf(account).call()
      .then((receipt) => {
        console.log('Get Piastr balance in BNB success:', receipt)
        setBalanceBnbPiastr(receipt / MULT_COEFF)
      })
      .catch((error) => {
        console.log('Get Piastr balance in BNB error:', error)
      })
    console.log('Get Escudo balance in BNB ...')
    contractBnbEscudo.methods.balanceOf(account).call()
      .then((receipt) => {
        console.log('Get Escudo balance in BNB success:', receipt)
        setBalanceBnbEscudo(receipt / MULT_COEFF)
      })
      .catch((error) => {
        console.log('Get Escudo balance in BNB error:', error)
      })
  }

  const getMainSepBalance = async () => {
    console.log('Get Main balance in Sepolia...')
    clientSep.eth.getBalance(account)
      .then((receipt) => {
        console.log('Get Main balance in Sepolia success:', receipt)
        setBalanceSepMain(receipt / MULT_COEFF)
      })
      .catch((error) => {
        console.log('Get Main balance in Sepolia error:', error)
      })
  }

  const getMainBnbBalance = async () => {
    console.log('Get Main balance in BNB...')
    clientBnb.eth.getBalance(account)
      .then((receipt) => {
        console.log('Get Main balance in BNB success:', receipt)
        setBalanceBnbMain(receipt / MULT_COEFF)
      })
      .catch((error) => {
        console.log('Get Main balance in BNB error:', error)
      })
  }

  const estimateGas = async (trxParams, onSuccess, onError) => {
    console.log('Estimate gas ...')
    web3.eth.estimateGas(trxParams)
      .then((gasVal) => {
        console.log('Estimate gas value:', gasVal)
        const gasValInt = parseInt(gasVal, 16)
        if (gasValInt > 0) {
          trxParams.gas = parseInt(gasValInt * 1.0).toString()
        }
        if (typeof onSuccess === 'function') {
          onSuccess(trxParams)
        }
      })
      .catch((error) => {
        console.log('Estimate gas error:', error)
        if (typeof onError === 'function') {
          onError()
        }
      })
  }

  const checkNetwork = () => {
    const chainId = tab
    if (selectedNetworkId === chainId) {
      doTransfer()
    } else {
      console.log('Change network to', chainId, '...')
      const data = CHAINS.find(el => el.chainId === chainId)
      const params = [
        {
          chainId: web3.utils.toHex(data.chainId),
          rpcUrls: [data.rpcUrl],
          chainName: data.name
        }
      ]
      window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: params
      })
      .then((receipt) => {
        console.log('Change network success.')
        doTransfer()
      })
      .catch((error) => {
        console.log('Change network error:', error)
      })
    }
  }

  const doTransfer = async () => {
    let balancePiastr = 0
    let balanceEscudo = 0
    if (tab === 11155111) {
      balancePiastr = balanceSepPiastr
      balanceEscudo = balanceSepEscudo
    } else if (tab === 97) {
      balancePiastr = balanceBnbPiastr
      balanceEscudo = balanceBnbEscudo
    }
    if (
      (tokenValue === 'Piastr' && Number(amountValue) > Number(balancePiastr)) ||
      (tokenValue === 'Escudo' && Number(amountValue) > Number(balanceEscudo))
    ) {
      return false
    }
    let contract
    if (tab === 11155111) {
      console.log('Do Transfer from Sepolia to BNB...')
      contract = contractSepBridge
    } else if (tab === 97) {
      console.log('Do Transfer from BNB to Sepolia...')
      contract = contractBnbBridge
    } else {
      console.log('Error: Unknown network.')
      return false
    }
    setFormLoading(true)
    const token = ADDRESSES.find(el => el.chainId === tab)[tokenValue]
    const trxParams = {
      to: contract._address,
      from: account,
      data: contract.methods.RequestXTransfer(token, ethers.utils.parseEther((Number(amountValue)).toString()), recipientValue).encodeABI()
    }
    estimateGas(trxParams, (trxParams) => {
      console.log('Transfer', amountValue, tokenValue, 'to', recipientValue, '...')
      web3.eth.sendTransaction(trxParams)
        .on('transactionHash', (trxHash) => {
          console.log('Transfer transactionHash:', trxHash)
        })
        .on('receipt', ({ logs }) => {
          if (logs) {
            console.log('Transfer', amountValue, tokenValue, 'to', recipientValue, 'success:', logs[0].data)
            if (selectedNetworkId === 11155111) {
              getMainSepBalance()
            } else if (selectedNetworkId === 97) {
              getMainBnbBalance()
            }
            getContractSepBalance()
            getContractBnbBalance()
            setFormVisible(false)
            setTokenValue('')
            setAmountValue('')
            setRecipientValue('')
            saveHashToStorage(logs[0].data + '|' + tab)
            getHashStatus(logs[0].data, contract)
          } else {
            console.log('Transfer', amountValue, tokenValue, 'to', recipientValue, 'error: no returned data.')
          }
          setFormLoading(false)
        })
        .on('error', (error) => {
          console.log('Transfer', amountValue, tokenValue, 'to', recipientValue, 'error:', error)
          setFormLoading(false)
        })
    }, () => {
      setFormLoading(false)
    })
  }

  const clearHashData = () => {
    savedHashsArr = []
    setSavedHashs(null)
  }

  const saveHashToStorage = (hash) => {
    if (typeof localStorage !== 'undefined') {
      console.log('Save hash to storage:', hash)
      const hashFromStorage = localStorage.getItem('SovinBridgeHashArr')
      const hashToStorage = hashFromStorage ? hashFromStorage.split(',') : []
      hashToStorage.push(hash)
      localStorage.setItem('SovinBridgeHashArr', hashToStorage.join(','))
    } else {
      console.log('There is no localStorage.')
    }
  }

  const readHashFromStorage = () => {
    if (typeof localStorage !== 'undefined') {
      clearHashData()
      const hashFromStorage = localStorage.getItem('SovinBridgeHashArr')
      const hashFromStorageArr = hashFromStorage ? hashFromStorage.split(',') : []
      console.log('Read hash from storage:', hashFromStorage)
      if (hashFromStorageArr.length > 0) {
        hashFromStorageArr.map((el) => {
          const data = el.split('|')
          let contract
          if (Number(data[1]) === 11155111) {
            contract = contractSepBridge
          } else if (Number(data[1]) === 97) {
            contract = contractBnbBridge
          } else {
            console.log('Error: Unknown network.')
            return false
          }
          getHashStatus(data[0], contract)
          return true
        })
      }
    } else {
      console.log('There is no localStorage.')
    }
  }

  const removeHashFromStorage = (hash) => {
    if (typeof localStorage !== 'undefined') {
      console.log('Remove hash', hash, 'from storage.')
      const hashFromStorage = localStorage.getItem('SovinBridgeHashArr')
      const hashFromStorageArr = hashFromStorage ? hashFromStorage.split(',') : []
      if (hashFromStorageArr.length > 0) {
        const hashToStorage = hashFromStorageArr.filter(el => !el.includes(hash))
        localStorage.setItem('SovinBridgeHashArr', hashToStorage.join(','))
      }
    } else {
      console.log('There is no localStorage.')
    }
  }

  const getHashStatus = async (hash, contract) => {
    console.log('Get hash', hash, 'status...')
    contract.methods.RequestState(hash).call()
      .then((status) => {
        if (status) {
          const today = createTimestamp(new Date())
          console.log('Get hash', hash, 'status success:', status)
          if (today - Number(status.dateUpdate) > HASH_TIME_LIMIT) {
            removeHashFromStorage(hash)
          } else {
            let newSavedHashsArr = [ ...savedHashsArr ]
            const elem = savedHashsArr.find(el => el.hash === hash)
            if (elem) {
              newSavedHashsArr.find(el => el.hash === hash).status = status
            } else {
              newSavedHashsArr = [ ...savedHashsArr, { hash: hash, status: status } ]
            }
            savedHashsArr = newSavedHashsArr
            setSavedHashs(savedHashsArr)
          }
        } else {
          console.log('Get hash', hash, 'status error: no returned status.')
        }
      })
      .catch((error) => {
        console.log('Get hash', hash, 'status error:', error)
      })
  }

  useEffect(() => {
    if (!loaded) {
      load()
    }
    window.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        setFormVisible(false)
        setTokenOpened(false)
      }
    })
  })

  useEffect(() => {
    const name = events.data.event
    console.log('Web3modal Event:', name)
  }, [events])

  useEffect(() => {
    if (!isConnected) {
      setAccount(null)
    }
  }, [isConnected])

  useEffect(() => {
    console.log('Network set to:', selectedNetworkId)
  }, [selectedNetworkId])

  useEffect(() => {
    if (!account || !address || account.toLowerCase() !== address.toLowerCase()) {
      setAccount(address)
    }
  }, [address])

  useEffect(() => {
    console.log('Account set to:', account)
    if (account) {
      clientsCreate()
    }
  }, [account])

  useEffect(() => {
    if (clientSep) {
      getMainSepBalance()
      contractsSepCreate()
    }
  }, [clientSep])

  useEffect(() => {
    if (clientBnb) {
      getMainBnbBalance()
      contractsBnbCreate()
    }
  }, [clientBnb])

  useEffect(() => {
    if (contractSepPiastr && contractSepEscudo) {
      getContractSepBalance()
    }
  }, [contractSepPiastr, contractSepEscudo])

  useEffect(() => {
    if (contractBnbPiastr && contractBnbEscudo) {
      getContractBnbBalance()
    }
  }, [contractBnbPiastr, contractBnbEscudo])

  useEffect(() => {
    if (contractSepBridge && contractBnbBridge) {
      readHashFromStorage()
    }
  }, [contractSepBridge, contractBnbBridge])

  const connectParams = CHAINS.length > 1 ? { view: 'Networks' } : {}

  let savedHashsSorted = []
  if (savedHashs) {
    savedHashsSorted = savedHashs.sort((a, b) => Number(a.status.dateUpdate) < Number(b.status.dateUpdate) ? 1 : Number(a.status.dateUpdate) > Number(b.status.dateUpdate) ? -1 : 0)
  }

  return (
    <div className="app" data-theme="dark" onClick={(e) => { e.stopPropagation(); setFormVisible(false); setTokenOpened(false) }}>
      <div className="blocks">
        {(isConnected && account) && <div className="block">
          <div className="title center num" data-network={selectedNetworkId}>{shortAddr(account)}</div>
        </div>}
        <div className="block controls">
          {isConnected
            ? <>
                <button onClick={() => open()}>Change Wallet</button>
                {account && <>
                  <div className="controls">
                  </div>
                </>}
              </>
            : <button onClick={() => open(connectParams)}>Connect Wallet</button>
          }
        </div>
        {(isConnected && account) && <>
          <hr />
          <div className="block controls tab-links">
            {CHAINS.map((el, key) => {
              return <button key={key} data-active={tab === el.chainId} data-actual={selectedNetworkId === el.chainId} onClick={() => setTab(el.chainId)}>{el.nameShort}</button>
            })}
          </div>
          <div className="block tab-content" data-active={tab === CHAINS[0].chainId}>
            <div className="balance-list">
              {balanceSepMain !== null && <div data-order="0" className="balance">
                <div className="token">ETH</div>
                <div className="price num" dangerouslySetInnerHTML={{ __html: balanceSepMain + CURRENCY_ICONS.find(el => el.currency === 'ETH').icon }} />
                </div>}
              {balanceSepPiastr !== null && <div data-order="1" className="balance">
                <div className="token">Piastr</div>
                <div className="price num" dangerouslySetInnerHTML={{ __html: balanceSepPiastr + CURRENCY_ICONS.find(el => el.currency === 'Piastr').icon }} />
                </div>}
              {balanceSepEscudo !== null && <div data-order="2" className="balance">
                <div className="token">Escudo</div>
                <div className="price num" dangerouslySetInnerHTML={{ __html: balanceSepEscudo + CURRENCY_ICONS.find(el => el.currency === 'Escudo').icon }} />
                </div>}
            </div>
            <button onClick={(e) => { e.stopPropagation(); setFormVisible() }}>Transfer to {CHAINS[1].nameShort}</button>
          </div>
          <div className="block tab-content" data-active={tab === CHAINS[1].chainId}>
            <div className="balance-list">
              {balanceBnbMain !== null && <div data-order="0" className="balance">
                <div className="token">BNB</div>
                <div className="price num" dangerouslySetInnerHTML={{ __html: balanceBnbMain + CURRENCY_ICONS.find(el => el.currency === 'BNB').icon }} />
                </div>}
              {balanceBnbPiastr !== null && <div data-order="1" className="balance">
                <div className="token">Piastr</div>
                <div className="price num" dangerouslySetInnerHTML={{ __html: balanceBnbPiastr + CURRENCY_ICONS.find(el => el.currency === 'Piastr').icon }} />
                </div>}
              {balanceBnbEscudo !== null && <div data-order="2" className="balance">
                <div className="token">Escudo</div>
                <div className="price num" dangerouslySetInnerHTML={{ __html: balanceBnbEscudo + CURRENCY_ICONS.find(el => el.currency === 'Escudo').icon }} />
                </div>}
            </div>
            <button onClick={(e) => { e.stopPropagation(); setFormVisible(true) }}>Transfer to {CHAINS[0].nameShort}</button>
          </div>
          {(savedHashsSorted.length > 0) && <>
            <hr />
            <div className="block">
              <div className="status-list">
                {savedHashs.map((el, key) => {
                  return <div key={key} className="status" data-status={el.status.state}>
                      <div>{shortAddr(el.hash)}</div>
                      <div>{BRIDGE_STATUSES.find(s => s.value === el.status.state).label}</div>
                    </div>
                })}
              </div>
            </div>
          </>}
          <div className="form zoomIn" data-visible={formVisible} onClick={(e) => e.stopPropagation()}>
            <div className="title" dangerouslySetInnerHTML={{ __html: 'Transfer to ' + CHAINS.find(el => el.chainId !== tab).nameShort }} />
            <div className="field">
              <div className="label">Token:</div>
              <div className="select" data-opened={tokenOpened} onClick={(e) => { e.stopPropagation() }}>
                <div className="selected" data-filled={tokenValue !== ''} onClick={(e) => { e.stopPropagation(); setTokenOpened(!tokenOpened) }}>
                  <span>{tokenValue}</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g className="fill"><path d="M20.207 8.147a1 1 0 0 0-1.414 0L12 14.94 5.207 8.147a1 1 0 0 0-1.414 1.414l7.5 7.5a.996.996 0 0 0 1.414.001l7.5-7.5a1 1 0 0 0 0-1.413z"/></g></svg>
                </div>
                {tokenOpened && <div className="options fadeInDown" onClick={(e) => e.stopPropagation()}>
                  {tokenOptions.map((el, key) => 
                    <div key={key} data-visible={el.value !== ''} data-active={tokenValue === el.value} onClick={() => { setTokenValue(el.value); setTokenOpened(false) }}>{el.label}</div>
                  )}
                </div>}
              </div>
            </div>
            <div className="field">
              <div className="label">Amount:</div>
              <input type="text" name="amount" value={amountValue} placeholder="amount" onChange={(e) => setAmountValue(e.target.value)} />
            </div>
            <div className="field">
              <div className="label-box">
                <span className="label">To</span>
                <span className="label">/</span>
                <span className="lnk" onClick={(e) => setRecipientValue(account)}>My</span>
              </div>
              <input type="text" name="amount" value={recipientValue} placeholder="address" onChange={(e) => setRecipientValue(e.target.value)} />
            </div>
            <div className="field buttons">
              {formLoading
                ? <div className="note flash">sending request...</div>
                : <>
                    <button type="reset" onClick={() => setFormVisible(false)}>Cancel</button>
                    <button type="submit" onClick={() => { checkNetwork() }} disabled={!tokenValue || !amountValue || !recipientValue}>Transfer</button>
                  </>
              }
            </div>
          </div>
        </>}
      </div>
    </div>
  )
}

export default App;
