// PROJECT_ID, METADATA - Parameters of project for Web3Modal
export const PROJECT_ID = '1ecd2300c3098e2d6571be046da01a58'
export const METADATA = {
  name: 'Notario',
  description: 'Notario',
  url: '',
  icons: []
}

export const CHAINS = [
  {
    chainId: 11155111,
    name: 'Sepolia Testnet',
    nameShort: 'Sepolia Testnet',
    currency: 'sepETH',
    explorerUrl: 'https://sepolia.etherscan.io/',
    rpcUrl: 'https://rpc.sepolia.org'
  },
  {
    chainId: 97,
    name: 'BNB Smart Chain Testnet',
    nameShort: 'BSC Testnet',
    currency: 'BNB',
    explorerUrl: 'https://testnet.bscscan.com',
    rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545'
  },
  /*
  {
    chainId: 56,
    name: 'BNB Smart Chain Mainnet',
    nameShort: 'BNB Mainnet',
    currency: 'BNB',
    explorerUrl: 'https://bscscan.com/',
    rpcUrl: 'https://bsc-dataseed1.binance.org/'
  },
  */
]

export const CURRENCY_ICONS = [
  {
    currency: 'BNB',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.611178 36"><g transform="translate(-522.6,-394.9)" class="fill"><path d="M 526.04555,412.9 L 526.06454,419.86284 L 531.98085,423.34426 V 427.42069 L 522.60211,421.92004 V 410.8639 Z M 526.04555,405.93716 V 409.9946 L 522.6,407.95638 V 403.89895 L 526.04555,401.86073 L 529.50798,403.89895 Z M 534.4516,403.89895 L 537.89715,401.86073 L 541.35958,403.89895 L 537.89715,405.93716 Z" /><path d="M 528.53529,418.41963 V 414.34321 L 531.98085,416.38142 V 420.43886 Z M 534.4516,424.80435 L 537.89715,426.84256 L 541.35958,424.80435 V 428.86179 L 537.89715,430.9 L 534.4516,428.86179 Z M 546.30109,403.89895 L 549.74664,401.86073 L 553.20907,403.89895 V 407.95638 L 549.74664,409.9946 V 405.93716 Z M 549.74664,419.86284 L 549.76563,412.9 L 553.21118,410.86179 V 421.91793 L 543.83245,427.41858 V 423.34215 Z" /><polygon points="1528.8,1605.3 1528.8,1413 1692.1,1316.4 1692.1,1509.6 " transform="matrix(0.02109952,0,0,0.02109952,511.57339,386.5678)" /><path d="M 547.27589,407.38037 L 547.29488,411.45679 L 541.36169,414.93821 V 421.91793 L 537.91614,423.93716 L 534.47059,421.91793 V 414.93821 L 528.5374,411.45679 V 407.38037 L 531.99773,405.34215 L 537.89504,408.84045 L 543.82823,405.34215 L 547.29066,407.38037 Z M 528.53529,400.41963 L 537.89715,394.9 L 547.27589,400.41963 L 543.83034,402.45785 L 537.89715,398.95955 L 531.98085,402.45785 Z" /></g></svg>'
  },
  {
    currency: 'ETH',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 32"><g transform="translate(-6,0)" class="fill"><path d="M15.927 23.959l-9.823-5.797 9.817 13.839 9.828-13.839-9.828 5.797zM16.073 0l-9.819 16.297 9.819 5.807 9.823-5.801z"/></g></svg>'
  },
  {
    currency: 'sepETH',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 32"><g transform="translate(-6,0)" class="fill"><path d="M15.927 23.959l-9.823-5.797 9.817 13.839 9.828-13.839-9.828 5.797zM16.073 0l-9.819 16.297 9.819 5.807 9.823-5.801z"/></g></svg>'
  },
  {
    currency: 'Piastr',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 96"><g transform="translate(-41,-30)" class="fill"><path d="M70.31 100.92a30.54 30.54 0 0 1 3.78 1.91l9-7.18 7.2-5.27V36.65l.79-.88c-.36-.23-14-5.44-14-5.44L69.39 38a62.42 62.42 0 0 0-7.23-6.5L51.75 39a61.67 61.67 0 0 1 6.11 5.58v45.03a20.69 20.69 0 0 0-5 1.56c-6.62 3.23-12.37 10.5-11.35 10.24.63-.15 3.73-3.35 10.05-3.82a37 37 0 0 1 6.34.09v16.59l-2.36 2.35 2.36 2v.38l.27-.15 8.12 6.84 8.79-9.1-5.28-4.37.55-.32zm8.83-57.59v48.79a35.34 35.34 0 0 0-8.67-2.68h-.16V39.9a47.37 47.37 0 0 1 5.91 2.1c1.09.5 2.06.94 2.92 1.33z" /></g></svg>'
  },
  {
    currency: 'Escudo',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272 384"><g transform="translate(-120,-64)" class="fill"><path d="M223.3 447.9c-23.4 0-42.8-4.3-58.1-12.8-15.4-8.5-26.7-19.7-34-33.5s-11-28.7-11-44.8c0-14.3 2.8-28.5 8.4-42.4 5.6-14 13.9-26.7 24.9-38.2s24.3-20.6 40.1-27.2c-14.3-9.1-25-20-32.2-32.7s-10.7-26.1-10.7-40.1c0-18.1 5.9-35.8 17.8-52.9s29.1-31.2 51.8-42.4C243 69.8 270.2 64.2 302 64.2c22 0 39.5 2.7 52.6 8.1s22.6 12.4 28.5 20.9c5.9 8.6 8.9 17.7 8.9 27.5 0 11.2-3.5 22.2-10.5 33.2s-17 20-30.1 27-29.1 10.5-47.9 10.5c5.6-16.4 8.4-30 8.4-40.8s-2.3-18.7-6.8-23.6-10.1-7.3-16.8-7.3c-7 0-14.4 2.6-22.2 7.8-7.9 5.2-14.4 12.6-19.6 22s-7.9 20.4-7.9 33c0 6.6 1 14.1 3.1 22.2s5.4 16.3 9.9 24.3c7.7-4.9 14.7-8.5 21.2-10.7 6.4-2.3 12.3-3.4 17.5-3.4 10.8 0 18.7 3.8 23.6 11.3s7.3 16 7.3 25.4c0 11.2-3.4 21.8-10.2 31.9s-16.8 15.2-30.1 15.2c-12.2 0-26.5-5.8-42.9-17.3-19.2 13.6-28.8 29.7-28.8 48.2 0 14.3 4.4 25.1 13.3 32.5 8.9 7.3 20.3 11 34.3 11 17.8 0 37.7-6.2 59.7-18.6s41.5-31 58.6-55.8l4.2 2.6c-.7 24.4-5.7 45.8-14.9 64.1s-21.3 33.6-36.1 45.8-31.3 21.4-49.5 27.5-36.7 9.2-55.5 9.2z"></path></g></svg>'
  }
]

export const ADDRESSES = [
  {
    chainId: 11155111,
    Piastr: '0x90A7704d3d2333C186F253E8B5aabCe467Cc5c4C',
    Escudo: '0xd5746A1C93D06Dbb7Efaa82f538D99282e44bF88',
    Bridge: '0x226b59c9d090A844472C59f8587ED7cE65089B88'
  },
  {
    chainId: 97,
    Piastr: '0x9B409D15bc0cccDeBcC572cb4C18b9cA8fD4Dc50',
    Escudo: '0xE7a712eBf2fd28C3743F767F704102842fb7a906',
    Bridge: '0x703BcE47f9e94FBbBE184Ec58388744c0fDDF938'
  },
]

export const MIN_ABI = [
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "Approval",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "Transfer",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "XMintTo",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "XRemoveFrom",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      }
    ],
    "name": "allowance",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "approve",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalSupply",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "transfer",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "transferFrom",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  }
]

export const BRIDGE_ABI = [
  {
    "inputs": [],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      }
    ],
    "name": "OwnableInvalidOwner",
    "type": "error"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "OwnableUnauthorizedAccount",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "QueueEmpty",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "QueueFull",
    "type": "error"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "bytes32",
        "name": "_reqHash",
        "type": "bytes32"
      }
    ],
    "name": "RequestCanceled",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "bytes32",
        "name": "_reqHash",
        "type": "bytes32"
      }
    ],
    "name": "RequestExecuted",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "bytes32",
        "name": "_reqHash",
        "type": "bytes32"
      }
    ],
    "name": "RequestRegistered",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_reqHash",
        "type": "bytes32"
      }
    ],
    "name": "CancelXTransfer",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_reqHash",
        "type": "bytes32"
      }
    ],
    "name": "ExecuteXTransfer",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "GetPendingRequestHash",
    "outputs": [
      {
        "internalType": "bytes32",
        "name": "_reqHash",
        "type": "bytes32"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "GetPendingRequestsCount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "_cnt",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_reqHash",
        "type": "bytes32"
      }
    ],
    "name": "RequestData",
    "outputs": [
      {
        "components": [
          {
            "internalType": "address",
            "name": "srcHodler",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "srcTokenAddr",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "value",
            "type": "uint256"
          },
          {
            "internalType": "address",
            "name": "dstHodler",
            "type": "address"
          },
          {
            "internalType": "address",
            "name": "dstTokenAddr",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "dateCreate",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "dateUpdate",
            "type": "uint256"
          },
          {
            "internalType": "uint256",
            "name": "state",
            "type": "uint256"
          }
        ],
        "internalType": "struct SovinBridge.XRequest",
        "name": "_request",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes32",
        "name": "_reqHash",
        "type": "bytes32"
      }
    ],
    "name": "RequestState",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "state",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "dateUpdate",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_srcTokenAddr",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_value",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "_dstHodler",
        "type": "address"
      }
    ],
    "name": "RequestXTransfer",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_srcTokenAddr",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_dstTokenAddr",
        "type": "address"
      }
    ],
    "name": "addTokenPair",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
]

export const BRIDGE_STATUSES = [
  {
    label: 'XR_STATE_PENDING',
    value: '1'
  },
  {
    label: 'XR_STATE_EXECUTED',
    value: '2'
  },
  {
    label: 'XR_STATE_CANCELED',
    value: '3'
  }
]

export const MULT_COEFF = Math.pow(10, 18)

export const HASH_TIME_LIMIT = 3 * 86400 // 3 days