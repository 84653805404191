export const createTimestamp = (date) => {
  return Math.round(Date.parse(date) / 1000)
}

export const convertTimestamp = (timestamp, format) => {
  const d = new Date(timestamp * 1000),
    yyyy = d.getFullYear(),
    mm = ('0' + (d.getMonth() + 1)).slice(-2),
    dd = ('0' + d.getDate()).slice(-2),
    hh = ('0' + d.getHours()).slice(-2),
    min = ('0' + d.getMinutes()).slice(-2)
  const date = format
    ? yyyy + '-' + mm + '-' + dd + ' ' + hh + ':' + min
    : dd + '.' + mm + '.' + yyyy + ' ' + hh + ':' + min
  return date
}

export const shortAddr = (addr) => {
  if (addr.length >= 12) {
    const l = addr.substr(0, 2).toLowerCase() === '0x' ? 6 : 4
    const r = 4
    addr = addr.substr(0, l) + '...' + addr.substr(-r)
  }
  return addr
}
